
import Vue from "vue";

import { Breadcrumb, Cancel } from "@/interfaces";

import { Route } from "vue-router";

import http from "@/http";

export interface DataType {
  cancel?: Cancel;
}

export default Vue.extend({
  data(): DataType {
    return {
      cancel: undefined
    };
  },
  async beforeRouteEnter(to: Route, from: Route, next: any) {
    const url = `/cancels/${to.params.id}`;

    const { data } = await http.get<Cancel>(url);

    next((vm: DataType & Vue) => {
      vm.cancel = data;
    });
  },
  computed: {
    breadcrumbs(): Breadcrumb[] {
      if (this.cancel == null) {
        return [];
      }

      return [
        {
          text: "ホーム",
          disabled: false,
          to: "/"
        },
        {
          text: "解約",
          disabled: false,
          to: "/cancels"
        },
        {
          text: `# ${this.cancel.id} ${this.cancel.room.building.name} ${this.cancel.room.display_number}`,
          disabled: true,
          to: `/cancel/${this.cancel.id}`
        }
      ];
    }
  }
});
